import ScalixLogoDark from "../../assets/scalix-logo-black.svg";

import MailLogo from "../../assets/mail.svg";
import hamburger_menu from "../../assets/hamburger_menu.svg";
import "./Header.css";
import { useState } from "react";

const Header = () => {
  const [isOpen, setisOpen] = useState(false);
  console.log(isOpen);

  if (isOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  return (
    <>
      <div className="header content-wrapper flex items-center justify-between px-5 py-3 ">
        <div className="mt-3 h-[70px] w-[70px] md:h-[50px] md:w-[105px]">
          <img src={ScalixLogoDark} alt="" />
        </div>
        <div className="hidden md:block">
          <div className="flex items-center gap-5 ">
            <div className="navbar-item">Home</div>
            <div className="navbar-item">About Us</div>
            <div className="navbar-item">Services</div>
            <div className="navbar-item">Testimonials</div>
            <div className="font-inria btn btn-primary flex w-[190px] items-center justify-center gap-4">
              <div>Contact Us</div>
              <button>
                <img src={MailLogo} alt="" width={18} height={18} />
              </button>
            </div>
          </div>
        </div>
        <button onClick={() => setisOpen(!isOpen)} className="md:hidden">
          <img src={hamburger_menu} width={50} height={50} alt="" />
        </button>
      </div>

      <nav
        className={`nav-menu nav-menu-vertical ${isOpen ? "nav-menu-open" : ""}`}
      >
        <div className="inline-block w-full transform overflow-auto rounded-lg text-center align-bottom shadow-xl transition-all sm:align-middle md:w-[400px]">
          <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div className="mt-3 text-left sm:ml-4 sm:mt-0 sm:text-left">
              <div className="flex flex-col items-center gap-5 text-white">
                <div className="navbar-item">Home</div>
                <div className="navbar-item">About Us</div>
                <div className="navbar-item">Services</div>
                <div className="navbar-item">Testimonials</div>
                <div className="font-inria btn btn-primary flex w-[190px] items-center justify-center gap-4">
                  <div>Contact Us</div>
                  <button>
                    <img src={MailLogo} alt="" width={18} height={18} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
