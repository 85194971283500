import "./App.css";
import { Route, Routes } from "react-router-dom";
import Landing from "./pages/landing/Landing";

function App() {  
  return (
    <Routes>
      <Route path="/" element={<Landing />}></Route>
    </Routes>
  );
}

export default App;
